import { Button, ButtonArea, InfoBanner, Modal, WarningBanner, WithLabel } from '@atrocit/scl';
import { DateTime } from 'luxon';
import { useCallback, useContext } from 'react';
import UserContext from '../../../context/UserContext';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import Papa from 'papaparse';

function getCompany(email): 'ATO' | 'BM' | 'Combinant' {
	if (email.includes('boortmalt.com')) return 'BM';
	if (email.includes('combinant.be')) return 'Combinant';
	return 'ATO';
}

function getFirmCode(company: 'ATO' | 'BM' | 'Combinant') {
	if (company == 'BM') return '614';
	if (company == 'Combinant') return '';
	return '639';
}

export default function GenerateHaloCsvModal({ workRecords, onClose }) {
	const doesNotNeedChange = new Set(workRecords.filter(wr => !wr.needsChange).map(wr => wr.id));
	const noPlanSpot = new Set(workRecords.filter(wr => wr.planSpot == null).map(wr => wr.id));
	const noShiftDefinition = new Set(workRecords.filter(wr => wr.planSpot?.planning?.shiftDefinition?.code == null).map(wr => wr.id));
	const noOrInvalidWorkbookNumber = new Set(workRecords.filter(wr => wr.dockWorkerTarget?.workbookNr == null || (wr.dockWorkerTarget?.workbookNr.split('/')[0] ?? '').trim().length != 5).map(wr => wr.id));
	const user = useContext(UserContext);
	const company = getCompany(user.email);
	const firmCode = getFirmCode(company);
	let downloadableWorkRecords = [];

	const data = [
		[ 'Firma', 'Dept', 'Werkboek', 'Shift', 'Subshift', 'Datum betrek', 'Vergoed', 'Loon', 'Premie', 'Misen', 'Kledij', 'Attestnr Kledij', 'Wt', 'Uitbetalen', 'Gewerkte functie' ],
		...workRecords.filter(wr => !doesNotNeedChange.has(wr.id) && !noPlanSpot.has(wr.id) && !noShiftDefinition.has(wr.id) && !noOrInvalidWorkbookNumber.has(wr.id)).map(wr => {
			downloadableWorkRecords.push(wr.id);
			return [
				firmCode,
				'0',
				(wr.dockWorkerTarget?.workbookNr.split('/')[0] ?? '').trim(),
				wr.planSpot?.planning?.shiftDefinition?.code, '0',
				DateTime.fromISO(wr.startDateTarget).toFormat('dd/MM/yyyy'),
				'1000',
				wr.calculatedWageTarget?.replace('.', ',') ?? 0,
				wr.calculatedPremiumTarget?.replace('.', ',') ?? 0,
				'', '', '',
				'J', 'J',
			];
		}),
	];

	const config = { delimiter: ';' };
	const csv = Papa.unparse(data, config);

	const onDownloadCsv = useCallback(() => {
		const element = document.createElement("a");
		element.href = URL.createObjectURL(new Blob([ csv ], { type: 'text/csv' }));
		element.download = "cepa-export.csv";
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
		onClose(downloadableWorkRecords);
	}, [ data ]);

	return <Modal isOpen={true} title="Exporteren naar HALO" onRequestClose={onClose}>
		<span>Dit genereert geen lijnen voor absenties.</span>
		<br />
		<br />
		<div style={{ display: 'flex', gap: 'var(--u-4)', flexDirection: 'column', paddingBottom: 'var(--u-8)' }}>
			{/* {noPlanSpot.size > 0 && <WarningBanner>{noPlanSpot.size} rijen weggelaten op basis van niet ingepland</WarningBanner>} */}
			{/* {noShiftDefinition.size > 0 && <WarningBanner>{noShiftDefinition.size} rijen weggelaten vanwege ontbrekende shift code</WarningBanner>} */}
			{[ ...noOrInvalidWorkbookNumber ].filter(wr => !noPlanSpot.has(wr) && !doesNotNeedChange.has(wr)).length > 0 && <WarningBanner>{[ ...noOrInvalidWorkbookNumber ].filter(wr => !noPlanSpot.has(wr) && !doesNotNeedChange.has(wr)).length} rij(en) weggelaten op basis van ontbrekend werkboeknummer</WarningBanner>}
			<InfoBanner>{(data.length - 1)} rijen getoond</InfoBanner>
		</div>
		<br />
		<WithLabel label="Voorbeeld">
			<pre style={{ width: '500px', overflow: 'auto', maxHeight: 'var(--u-256)', fontFamily: 'monospace', padding: 'var(--u-4)', background: 'var(--col-grey-100)', borderRadius: 'var(--u-4)', boxShadow: '0 1px 4px inset rgba(0, 0, 0, 0.1)' }}>
				{csv}
			</pre>
		</WithLabel>

		<ButtonArea>
			<Button onClick={() => onClose()}>Annuleren</Button>
			{/* <Button onClick={() => {}} level="secondary"><span className="fa fa-file-excel-o" />&nbsp; Download Excel</Button> */}
			<ValidatedButton level="primary" onClick={onDownloadCsv} validations={[
				{ valid: data.length > 1, error: 'Er moet minstens één rij zijn om af te drukken' },
			]}><span className="fa fa-file" />&nbsp; Download CSV</ValidatedButton>
		</ButtonArea>
	</Modal>;
}