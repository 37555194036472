import TopBar from '../TopBar';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { FormattedDate, FormattedNumber } from 'react-intl';
import UserSelectField from '../lib/selectfields/UserSelectField';
import PageTop from '../lib/ui/PageTop';
import { Button, LinkButton, LoadingOverlay, LoadingOverlayProvider, LoadingOverlayTrigger, Pagination } from '@atrocit/scl';
import AddSavingRecordModal from './AddSavingRecordModal';
import UserContext from '../../context/UserContext';
import SelectField from '../lib/selectfields/SelectField';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';
import { PrivilegesContext } from '../../context/PrivilegesContext';
import { useConfiguration } from '../../hooks/useConfiguration';
const SavingsExcelExport = React.lazy(() => import('./SavingsExcelExport'));

export default function SavingsOverview() {
	const me = useContext(UserContext);
	const userPrivileges = useContext(PrivilegesContext);
	const onlyMyRecords = !userPrivileges.has('READ_ALL_SAVINGS_RECORDS');
	const [ page, setPage ] = useState(1);
	const [ user, setUser ] = useState(onlyMyRecords ? me.id : null);
	const [ approvalStatus, setApprovalStatus ] = useState(onlyMyRecords ? true : null);
	const [ addSavingRecordModal, setAddSavingRecordModal ] = useState(false);

	useEffect(() => {
		if (page != 1) setPage(1);
	}, [ user ]);

	const recordsQuery = useQuery(gql`query Query($userId: Int, $page: Int!, $approvalStatus: Boolean) {
		savingRecords(page: $page, userId: $userId, approvalStatus: $approvalStatus) {
			totalElements,
			content {
				id,
				applied,
				createdAt,
				createdBy {
					id, firstName, fullName
				},
				approved,
				approvedBy {
					id, firstName, fullName
				},
				dockWorkerRole {
					user {
						id,
						fullName,
					}
                },
				wage,
				premium,
				remark,
				removed,
				removedBy {
					id, firstName, fullName
                }
            }
		}
	}`, { variables: { page, userId: user, approvalStatus } });
	const records = recordsQuery?.data?.savingRecords?.content ?? [];

	const savingRecordTotalsQuery = useQuery(gql`query Query($userId: Int) {
        savingRecordTotals(userId: $userId, includeUnapproved: false) {
	        wageTotal,
	        premiumTotal
        }
    }`, { variables: { userId: user } });
	const savingRecordTotals = savingRecordTotalsQuery?.data?.savingRecordTotals ?? {};

	const [ approveRecord ] = useMutation(gql`mutation Mutation($savingRecordId: Int!) {
        approveSavingRecord(savingRecordId: $savingRecordId) {
	        id, approved, approvedBy {
		        id, firstName, fullName
            }
        }
	}`);

	const [ removeRecord ] = useMutation(gql`mutation Mutation($savingRecordId: Int!) {
		removeSavingRecord(savingRecordId: $savingRecordId) {
			id,
			removed,
			removedBy {
				id, firstName, fullName
            }
		}
	}`);

	const [ approveRemovalOfRecord ] = useMutation(gql`mutation Mutation($savingRecordId: Int!) {
        approveRemovalOfSavingRecord(savingRecordId: $savingRecordId) {
            id,
            removed,
            removedBy {
                id, firstName, fullName
            },
	        removalApproved,
	        removalApprovedBy {
		        id, firstName, fullName
            }
        }
    }`);

	const cfg = useConfiguration();

	return <>
		<TopBar title={'Briefjes'} />

		<div className="grey-page-bg">
			<div className="page">
				<AddSavingRecordModal
					isOpen={addSavingRecordModal}
					onClose={() => {
						recordsQuery.refetch();
						setAddSavingRecordModal(false);
					}} />

				<PageTop breadcrumbSegments={[ { link: '/savings', label: 'Briefjes' }, { link: '/savings', label: 'Overzicht' } ]}>
					<div style={{ display: 'flex', gap: 'var(--u-16)' }}>
						<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS' ]}>
							<Suspense fallback={<></>}>
								<SavingsExcelExport userId={user} approvalStatus={approvalStatus} />
							</Suspense>
						</PrivilegeBarrier>

						<PrivilegeBarrier privileges={[ 'WRITE_SAVINGS_RECORDS' ]}>
							<Button level="primary" onClick={() => setAddSavingRecordModal(true)}><span className="fa fa-plus" />&nbsp; Briefje zetten/opnemen</Button>
						</PrivilegeBarrier>
					</div>
				</PageTop>

				<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS' ]}>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr 200px', gap: 'var(--u-16)' }}>
						<UserSelectField allowDeleted={true} onChange={setUser} value={user} placeholder="Selecteer gebruiker om te filteren..." />
						<SelectField
							options={[
								{ value: null, label: 'Alles' },
								{ value: false, label: 'Nog te checken' },
								{ value: true, label: 'Goedgekeurd' },
							]}
							onChange={e => setApprovalStatus(e?.value)}
							value={approvalStatus} />
					</div>
				</PrivilegeBarrier>
				<br />

				<div className="savings-stats" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
					<div>
						<div className={"fancy-number" + (savingRecordTotals?.wageTotal < 0 ? ' fancy-number-neg' : '')}>
							<span className="fancy-number-description">Loon</span><br />
							<FormattedNumber value={savingRecordTotals?.wageTotal || 0} style="currency" currency="EUR" />
						</div>
					</div>
					<div>
						<div className={"fancy-number" + (savingRecordTotals?.premiumTotal < 0 ? ' fancy-number-neg' : '')}>
							<span className="fancy-number-description">Premie</span><br />
							<FormattedNumber value={savingRecordTotals?.premiumTotal || 0} style="currency" currency="EUR" />
						</div>
					</div>
				</div>

				<div style={{ position: 'relative', zIndex: 0 }}>
					<LoadingOverlayProvider>
						{recordsQuery.loading && <LoadingOverlayTrigger />}
						<table className="table table-fw" style={{ minWidth: 1200 }}>
							<colgroup>
								<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS', 'WRITE_SAVINGS_RECORDS' ]}>
									<col width="48" />
									<col width="48" />
								</PrivilegeBarrier>
								<col width="200" />
								<col width="130" />
								<col width="130" />
								<col width="90" />
								<col width="90" />
								<col width="*" />
								<PrivilegeBarrier privileges={[ 'WRITE_SAVINGS_RECORDS' ]}>
									<col width="48" />
								</PrivilegeBarrier>
							</colgroup>
							<thead>
								<tr>
									<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS', 'WRITE_SAVINGS_RECORDS' ]}>
										<th></th>
										<th></th>
									</PrivilegeBarrier>
									<th>Arbeider</th>
									<th>Eff. datum <span className="fa fa-caret-down" /></th>
									<th>Aangemaakt</th>
									<th className="tbl-align-right">Loon</th>
									<th className="tbl-align-right">Premie</th>
									<th>Opmerking</th>
									<PrivilegeBarrier privileges={[ 'WRITE_SAVINGS_RECORDS' ]}>
										<th />
									</PrivilegeBarrier>
								</tr>
							</thead>
							<tbody>
								{records.map(r => <tr key={r.id} className={r.removed != null ? 'strikethrough' : ''}>
									<PrivilegeBarrier privileges={[ 'WRITE_SAVINGS_RECORDS' ]}>
										<td style={{ textAlign: 'center' }}>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												{r.approved != null && <span className="fa fa-check-circle" style={{ color: 'var(--col-green-500)' }} title={"Goedgekeurd op " + DateTime.fromISO(r.approved).toFormat('dd-MM-yyyy HH:mm') + (r.approvedBy != null ? ',\n goedkeuring door ' + r.approvedBy.firstName : '') + (r.createdBy != null ? ',\n origineel aangemaakt door ' + r.createdBy?.firstName : '')} />}
												{r.approved == null && (!cfg.allowSelfApproval && r.createdBy?.id == me.id) && <span className="fa fa-clock-o" style={{ color: 'var(--col-primary-600)' }} title={'Wachten op goedkeuring'} />}
												{r.approved == null && (cfg.allowSelfApproval || r.createdBy?.id != me.id) && <Button onClick={() => approveRecord({ variables: { savingRecordId: r.id } }).finally(() => recordsQuery.refetch())}><span className="fa fa-check" /></Button>}
											</div>
										</td>
										<td style={{ textAlign: 'center' }}>
											<LinkButton to={"/savings/" + r.id}><span className="fa fa-eye" /></LinkButton>
										</td>
									</PrivilegeBarrier>
									<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS' ]} excludeOnThese={[ 'WRITE_SAVINGS_RECORDS' ]}>
										<td style={{ textAlign: 'center' }}>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												{r.approved != null && <span className="fa fa-check-circle" style={{ color: 'var(--col-green-500)' }} title={"Goedgekeurd op " + DateTime.fromISO(r.approved).toFormat('dd-MM-yyyy HH:mm') + (r.approvedBy != null ? ',\n goedkeuring door ' + r.approvedBy.firstName : '') + (r.createdBy != null ? ',\n origineel aangemaakt door ' + r.createdBy?.firstName : '')} />}
												{r.approved == null && <span className="fa fa-clock-o" style={{ color: 'var(--col-primary-600)' }} title={'Wachten op goedkeuring'} />}
											</div>
										</td>
										<td style={{ textAlign: 'center' }}>
											<LinkButton to={"/savings/" + r.id}><span className="fa fa-eye" /></LinkButton>
										</td>
									</PrivilegeBarrier>
									<td>{r.dockWorkerRole?.user?.fullName ?? '-'}</td>
									<td><FormattedDate value={DateTime.fromISO(r.applied).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
									<td><FormattedDate value={DateTime.fromISO(r.createdAt).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
									{/* eslint-disable-next-line react/style-prop-object */}
									<td className={"tbl-align-right" + (r.wage == 0 ? ' tbl-faded' : '')}>
										<FormattedNumber value={r.wage} style="currency" currency="EUR" />
									</td>
									<td className={'tbl-align-right' + (r.premium == 0 ? ' tbl-faded' : '')}>
										<FormattedNumber value={r.premium} style="currency" currency="EUR" />
									</td>
									<td title={r.remark}>
										{r.remark}
									</td>
									<PrivilegeBarrier privileges={[ 'WRITE_SAVINGS_RECORDS' ]}>
										<td className="no-strikethrough">
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												{r.removed == null && <Button onClick={() => removeRecord({ variables: { savingRecordId: r.id } }).finally(() => recordsQuery.refetch())}><span className="fa fa-trash" /></Button>}
												{r.removed != null && (!cfg.allowSelfApproval && r.removedBy?.id == me.id) && <span className="fa fa-clock-o" style={{ color: 'var(--col-primary-600)' }} title={'Wachten op goedkeuring voor verwijderen'} />}
												{r.removed != null && (cfg.allowSelfApproval || r.removedBy?.id != me.id) && <Button onClick={() => approveRemovalOfRecord({ variables: { savingRecordId: r.id } }).finally(() => recordsQuery.refetch())}>
													<span className="fa fa-trash" />
													<div style={{ position: 'relative', left: '-5px', top: '4px', marginRight: '-7px', fontSize: '.5em', background: 'var(--col-grey-700)', border: '1px solid var(--col-grey-200)', borderRadius: '50%', width: '13px', height: '13px', color: 'white', lineHeight: '13px', textAlign: 'center' }}>
														<span className="fa fa-check" />
													</div>
												</Button>}
											</div>
										</td>
									</PrivilegeBarrier>
								</tr>)}
								{records.length == 0 && <tr><td colSpan={6 + (!onlyMyRecords ? 1 : 0) + (userPrivileges.has('WRITE_SAVINGS_RECORDS') ? 1 : 0)}>Geen resultaten</td></tr>}
							</tbody>
						</table>
					</LoadingOverlayProvider>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)' }}>
					<Pagination page={page} pageSize={25} setPage={setPage} total={recordsQuery?.data?.savingRecords?.totalElements} />
				</div>
			</div>
		</div>
	</>;
}