import { gql, useMutation, useQuery } from '@apollo/client';
import TopBar from '../TopBar';
import { Button, LoadingOverlay } from '@atrocit/scl';
import { AbsenceType } from '../absence/AbsenceType';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import React from 'react';

export default function AbsencesToApprove() {
	const query = useQuery(gql`{
        absencesToApprove {
	        id,
	        user {
		        id,
		        fullName,
		        dockWorkerRole {
					workbookNr,
                }
	        },
	        start,
	        end,
	        absenceStatus,
	        absenceType,
	        remark
        }
	}`);
	const absences = query?.data?.absencesToApprove ?? [];

	const [ changeAbsenceMutation ] = useMutation(gql`mutation Mutation($aid: Int!) {
		approveAbsence(absenceId: $aid) {
			id,
			absenceStatus,
		}
    }`);

	return <>
		<TopBar title="Absenties goed te keuren" />
		<div className="grey-page-bg">
			<div className="page">
				{query.loading && <LoadingOverlay />}

				<br /><br />
				<table className="table table-fw">
					<colgroup>
						<col width="48" />
						<col width="230" />
						<col width="200" />
						<col width="150" />
						<col width="150" />
						<col width="*" />
					</colgroup>
					{/* <col width="" />*/}
					<thead>
						<tr>
							<th />
							<th>Arbeider</th>
							<th>Type</th>
							<th>Van</th>
							<th>Tot</th>
							<th>Opmerking</th>
						</tr>
					</thead>
					<tbody>
						{absences.map(a => <tr key={a.id}>
							<td className="tbl-center">
								{a.absenceStatus == 'APPROVED' && <span className="fa fa-check fa-check-circle green" />}
								{a.absenceStatus == 'REQUESTED' && <Button
									onClick={() => {
										changeAbsenceMutation({
											variables: {
												aid: a.id,
											},
										});
									}}>
									<span className="fa fa-check" />
								</Button>}
							</td>
							<td>{a.user?.fullName} <span title={'Werkboeknummer: ' + a.user?.dockWorkerRole?.workbookNr} className="diff-old"> {a.user?.dockWorkerRole?.workbookNr} </span></td>
							<td><AbsenceType type={a.absenceType} /></td>
							<td><FormattedDate value={DateTime.fromISO(a.start).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td><FormattedDate value={DateTime.fromISO(a.end).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td>{a.remark}</td>
						</tr>)}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}