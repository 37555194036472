import { gql, useQuery, useSubscription } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { LoadingOverlayProvider, LoadingOverlayTrigger, usePersistentState } from '@atrocit/scl';
import TopBar from '../../TopBar';
import PageTop from '../../lib/ui/PageTop';
import ButtonArea from '../../lib/forms/ButtonArea';
import ViewSelectField from '../../lib/selectfields/ViewSelectField';
import WeekSelector from '../../lib/forms/WeekSelector';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
import WeekRemark from './WeekRemark';
import ColorDot from '../../lib/ui/ColorDot';
import { FormattedDate } from 'react-intl';
import { AbsenceWeekDay, ShowSpecialDays } from '../../absence/AbsenceWeek';
import { useMemo } from 'react';

export default function PersonBasedView() {
	const navigate = useNavigate();
	const { weekYear } = useParams();

	// The week number + year is encoded in the URL
	const startOfWeek = (weekYear ? DateTime.fromFormat(weekYear, 'kkkk-WW') : (DateTime.now().plus({ days: 2 })).startOf('week'));
	const start = startOfWeek.minus({ days: 2 });
	const setStartOfWeek = (dtOrFn) => {
		const newDt = typeof dtOrFn == 'function' ? dtOrFn(startOfWeek) : dtOrFn;
		navigate('/shifts/byperson/' + newDt.toFormat('kkkk-WW'));
	};

	const [ view, setView ] = usePersistentState(null, 'calendar.selectedView'); // TODO: figure out a way to default fill this to user default

	const viewSubscription = useSubscription(gql`subscription Subscription($viewId: Int!, $start: Instant!, $end: Instant!) {
		viewSubscription(viewId: $viewId, startDate: $start, endDate: $end) {
			view {
				id, name,
				shiftDefinitions {
					id,
					name,
					startTime,
					endTime,
                }
            },
			planSpots {
				id,
				track { id },
				planning {
					id,
					shiftStart,
					shiftEnd,
					shiftDefinition {
						id, name
                	}
				}
				dockWorker {
					id,
					allowedRoles {
						id
                    }
                }
				role { id, shortCode, colorCode }
            }
		}
	}`, { variables: { viewId: view, start: start.toUTC().toISO(), end: start.plus({ weeks: 1 }).toUTC().toISO() }, skip: view == null });
	const viewObject = viewSubscription?.data?.viewSubscription?.view ?? null;
	const spots = viewSubscription?.data?.viewSubscription?.planSpots ?? [];

	const absencesQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		absencesInRange(start: $start, end: $end) {
			id,
			start,
			end,
			user {
				id,
				fullName,
				dockWorkerRole {
					id,
					workbookNr,
                }
			},
			absenceType,
			absenceStatus,
			remark,
		}
	}`, { pollInterval: 10 * 1000, variables: { start: start.toUTC().toISO(), end: start.plus({ weeks: 1 }).toUTC().toISO() } });
	const absences = useMemo(() => {
		return (absencesQuery?.data?.absencesInRange ?? []).map(a => ({ ...a, start: DateTime.fromISO(a.start), end: DateTime.fromISO(a.end) }));
	}, [ absencesQuery?.data?.absencesInRange ]);

	const allUsersQuery = useQuery(gql`
		query Query {
			users {
				id,
				fullName,
				dockWorkerRole {
					id,
					workbookNr,
                },
				deletedOn,
            }
		}
	`);
	const dockWorkers = (allUsersQuery?.data?.users ?? [])
		.filter(u => u.deletedOn == null)
		.filter(u => u.dockWorkerRole != null)
		.toSorted((a, b) => a.fullName.localeCompare(b.fullName));

	const specialDaysQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		specialDays(start: $start, end: $end) {
			id, date, name, colorCode
		}
	}`, { variables: { start: startOfWeek.minus({ days: 2 }).toUTC().toISO(), end: startOfWeek.minus({ days: 2 }).plus({ weeks: 1 }).toUTC().toISO() } });
	const specialDays = specialDaysQuery?.data?.specialDays ?? [];

	return <div style={{ height: '100vh', overflow: 'auto', position: 'relative', display: 'flex', flexDirection: 'column' }}>
		<TopBar title={'Week ' + startOfWeek.toFormat('WW, kkkk')} />
		<div className="grey-page-bg" style={{ flex: 1, overflow: 'auto' }}>
			<div className="page" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
				<PageTop breadcrumbSegments={[ { link: '/shifts', label: 'Shifts' }, { link: '/shifts/byperson', label: 'Per persoon' }, { link: '/shifts/byperson/' + startOfWeek.toFormat('kkkk-WW'), label: 'Week ' + startOfWeek.toFormat('WW, kkkk') } ]} />

				<ButtonArea>
					<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-8)' }}>
						<div style={{ marginRight: '16px' }}>
							<div style={{ minWidth: '150px' }}>
								<ViewSelectField onChange={setView} value={view} />
							</div>
						</div>

						<WeekSelector setStartOfWeek={setStartOfWeek} startOfWeek={startOfWeek} />
					</div>
				</ButtonArea>
				<br /><br />

				<div style={{ position: 'relative', zIndex: 0, minWidth: '1100px' }}>
					<LoadingOverlayProvider>
						{viewSubscription.loading && <LoadingOverlayTrigger />}

						<div className="abs-week">
							<div className="abs-week-row abs-week-header" style={{ position: 'sticky', top: 0, zIndex: 2, background: 'var(--col-grey-50)' }}>
								<div />
								<div><FormattedDate value={start} weekday="long" /><br /><FormattedDate value={start} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start} /></div>
								<div><FormattedDate value={start.plus({ days: 1 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 1 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 1 })} /></div>
								<div><FormattedDate value={start.plus({ days: 2 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 2 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 2 })} /></div>
								<div><FormattedDate value={start.plus({ days: 3 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 3 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 3 })} /></div>
								<div><FormattedDate value={start.plus({ days: 4 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 4 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 4 })} /></div>
								<div><FormattedDate value={start.plus({ days: 5 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 5 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 5 })} /></div>
								<div><FormattedDate value={start.plus({ days: 6 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 6 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 6 })} /></div>
							</div>
							{dockWorkers.map(dw => <div key={dw.id} className="abs-week-row">
								<div style={{ position: 'sticky', left: 0, zIndex: 1, marginRight: 'var(--u-16)' }}>
									<div style={{ display: 'inline-block', background: 'var(--col-grey-50)' }}>{dw?.fullName} <span title={'Werkboeknummer: ' + dw?.dockWorkerRole?.workbookNr} className="diff-old"> {dw?.dockWorkerRole?.workbookNr} </span></div>
								</div>
								{[ 0, 1, 2, 3, 4, 5, 6 ].map(offset => {
									const currentDate = start.plus({ days: offset });
									console.log(currentDate);

									const spot = spots
										.filter(s => s.dockWorker?.id == dw.dockWorkerRole?.id)
										.find(s => DateTime.fromISO(s.planning?.shiftStart) >= currentDate && DateTime.fromISO(s.planning?.shiftStart) < currentDate.plus({ days: 1 }));

									const myAbsences = absences.filter(a => a.user?.id == dw.id);
									if (myAbsences.length > 0) console.log(myAbsences);

									return <div>
										{spot && <div style={{ border: '1px solid var(--col-grey-100)', borderRadius: '4px', padding: '0 var(--u-4)', textAlign: 'center' }}>
											<ColorDot hex={spot?.role?.colorCode} /> {spot?.role?.shortCode} &mdash; {spot?.planning?.shiftDefinition?.name}
										</div>}
										{myAbsences.length > 0 && <div>
											<AbsenceWeekDay absences={myAbsences} start={currentDate} startOfWeek={offset == 0} endOfWeek={offset == 6} onEdit={() => {}} />
										</div>}
									</div>;
								})}
							</div>)}
						</div>
					</LoadingOverlayProvider>
				</div>

				<div>
					<PrivilegeBarrier privileges={[ 'READ_WEEK_REMARKS' ]}>
						<WeekRemark viewId={viewObject?.id} weekStart={startOfWeek} />
					</PrivilegeBarrier>
				</div>
			</div>
		</div>
	</div>;
}