import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../context/UserContext';
import { FormattedDate, FormattedRelativeTime, FormattedTime } from 'react-intl';
import LinkButton from '../lib/forms/LinkButton';
import ButtonArea from '../lib/forms/ButtonArea';
import ColorDot from '../lib/ui/ColorDot';
import hammockImg from '../../assets/hammock_blue.png';
import EmptyState from '../lib/EmptyState';
import { Button, InfoBanner, LoadingOverlay } from '@atrocit/scl';
import { DateTime } from 'luxon';

export default function MyShifts() {
	const user = useContext(userContext);
	const [ loading, setLoading ] = useState(false);
	const [ lastFetch, setLastFetch ] = useState(DateTime.now());

	const [ doQuery, myShiftsQuery ] = useLazyQuery(gql`{
		showMyPlannings {
			id, shiftStart, shiftEnd, remarks {
				id, remark
			},
			spots {
				id,
				role {
					id, shortCode, name, colorCode
                }
				listIndex,
				planning {
					id,
					view {
						id,
						attachedTracks {
							id, displayName,
							track { id }
                        }
					}
                }
				track { id },
                start,
                end,
				remark,
				dockWorker {
					user {
						id, firstName, fullName
                    }
                }
            }
        }
	}`);
	useEffect(() => {
		doQuery();
	}, []);

	const shifts = myShiftsQuery?.data?.showMyPlannings || [];
	const isEmpty = myShiftsQuery?.data?.showMyPlannings != null && myShiftsQuery?.data?.showMyPlannings.length == 0;

	useEffect(() => {
		setLastFetch(DateTime.now());
	}, [ myShiftsQuery.data ]);

	useEffect(() => {
		const int = setInterval(() => doQuery(), 60 * 1000);
		return () => clearInterval(int);
	}, [ doQuery ]);

	const refreshSecondsAgo = Math.round(DateTime.now().diff(lastFetch, [ 'seconds' ]).toObject().seconds);
	const refreshUnit = (() => {
		if (Math.abs(refreshSecondsAgo) <= 60) return 'second';
		if (Math.abs(refreshSecondsAgo) > 60) return 'minute';
		if (Math.abs(refreshSecondsAgo) > 3600) return 'hour';
		if (Math.abs(refreshSecondsAgo) > 3600 * 24) return 'day';
		if (Math.abs(refreshSecondsAgo) > 3600 * 24 * 7) return 'day';
		return 'week';
	})();

	return <>
		{myShiftsQuery.loading && <LoadingOverlay />}
		<TopBar title="Mijn shifts">
			<Button
				onClick={() => {
					doQuery();
					setLoading(true);
					setTimeout(() => setLoading(false), 500);
				}}
				style={{ minWidth: '40px', textAlign: 'center' }}>
				<span className={"fa fa-refresh" + (loading || myShiftsQuery.loading ? ' fa-spin' : '')} />
			</Button>
		</TopBar>

		<div className="grey-page-bg">
			<div className="page">
				<PageTop />

				{isEmpty && <EmptyState img={hammockImg} title="Niets gepland" description="Er zijn de komende dagen geen shifts waarop je ingepland staat" />}

				<div className="shift-view">
					{shifts.map(shift => {
						const mySpot = shift.spots.find(s => s.dockWorker?.user?.id == user.id);
						return <Shift key={shift.id} shift={shift} mySpot={mySpot} />;
					})}
				</div>

				<div style={{ textAlign: 'center', color: 'var(--col-grey-500)', padding: 'var(--u-32) 0' }}>
					<FormattedRelativeTime updateIntervalInSeconds={1} value={Math.min(-1 * refreshSecondsAgo, -1)} unit={refreshUnit} /> ververst
				</div>
			</div>
		</div>
	</>;
}

function Shift({ shift, mySpot }) {
	const remarkQuery = useQuery(gql`query Query($planningId: Int!) {
		findRemarksByPlanning(planningId: $planningId) {
			id, remark,
		}
	}`, { variables: { planningId: shift?.id }, skip: shift == null || shift?.id == null });
	const remarks = remarkQuery?.data?.findRemarksByPlanning ?? [];

	return <div className="collapsible-shift" style={{ marginBottom: '8px', background: 'var(--col-white)', borderRadius: '4px', boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1), 1px 2px 16px rgba(0, 0, 0, 0.07)', padding: '8px 16px' }}>
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid var(--col-grey-200)', paddingBottom: '4px' }}>
			<div style={{ fontSize: 'var(--fs-16)', textAlign: 'right' }}>
				<FormattedDate value={shift.shiftStart} weekday="short" day="2-digit" month="2-digit" />
			</div>
			<div style={{ marginLeft: '8px', textDecoration: mySpot.start && mySpot.end && 'line-through' }}>
				<FormattedTime value={shift.shiftStart} hour="2-digit" minute="2-digit" /> - <FormattedTime value={shift.shiftEnd} hour="2-digit" minute="2-digit" /><br />
			</div>
		</div>
		{mySpot.start && mySpot.end && <div style={{ display: 'flex', minHeight: '24px', alignItems: 'center' }}>
			<div className='date-time-display'>Aangepaste tijden: <FormattedTime value={mySpot.start} /> - <FormattedTime value={mySpot.end} /></div>
		</div>}
		<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-4)', paddingTop: 'var(--u-4)' }}>
			{remarks.filter(r => r.remark?.trim().length > 0).map(r => <InfoBanner key={r.id}>{r.remark}</InfoBanner>)}
		</div>
		<div style={{ padding: '16px 0', fontSize: 'var(--fs-16)', lineHeight: 'var(--fs-24)' }}>
			<ColorDot hex={mySpot.role?.colorCode} /> {mySpot.role?.shortCode} - {mySpot.planning?.view?.attachedTracks.find(at => at.track.id == mySpot.track?.id).displayName}<br />
			{mySpot.remark}
		</div>
		<div>
			<ButtonArea>
				<LinkButton to={"/shifts/view/" + shift.id}>Bekijken&nbsp;&nbsp; <span className="fa fa-chevron-right" /></LinkButton>
			</ButtonArea>
		</div>
	</div>;
}