import { Button } from '@atrocit/scl';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';
import { DateTime } from 'luxon';
import ColorDot from '../../lib/ui/ColorDot';
import { AbsenceType } from '../../absence/AbsenceType';
import { gql, useMutation } from '@apollo/client';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
import React from 'react';

export default function WorkRecordDockWorker({ dockWorker, workRecords, highlightNewEntries = false }) {
	const [ processWorkRecord ] = useMutation(gql`mutation Mutation($id: Int!) {
        processWorkRecord(workRecord: $id) {
            id,
            needsChange,
        }
    }`);

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
	};


	return <div style={{ padding: 'var(--u-16) 0' }}>
		<div style={{ fontSize: 'var(--fs-13)', paddingBottom: 'var(--u-8)' }}>
			<span style={{ fontWeight: 'bold' }}>{dockWorker.user.fullName}</span>, werkboeknr <span style={{ fontWeight: 'bold' }}>{(dockWorker.workbookNr?.trim() || '-')}</span>
		</div>
		<div>
			<table className="table table-fw">
				<colgroup>
					{highlightNewEntries && <col width="23" />}
					<col width="40" />
					<col width="40" />
					<col width="130" />
					<col width="70" />
					<col width="70" />
					<col width="100" />
					<col width="100" />
					<col width="*" />
					<col width="100" />
					<col width="100" />
				</colgroup>
				<thead>
					<tr>
						{highlightNewEntries && <th title={'In te voeren (+) of een wijziging (!)'} />}
						<th className="tbl-center">HALO</th>
						<th>Dag</th>
						<th>Datum</th>
						<th>Start</th>
						<th>Eind</th>
						<th>Rol</th>
						<th>Ab.type</th>
						<th>Opmerking</th>
						<th>Loon</th>
						<th>Premie</th>
					</tr>
				</thead>
				<tbody>
					{workRecords.map(r => {
						return <>
							<tr key={r.id}>
								{highlightNewEntries && <td>{r.needsChange ? (r.dockWorkerEntered != null ? <span title={'Wijziging'} className="fa fa-exclamation-circle" /> : <span title={'In te voeren'} className="fa fa-plus" />) : ''}</td>}
								<td className="tbl-center">
									{!r.needsChange && <span className="fa fa-check fa-check-circle green" />}
									<PrivilegeBarrier privileges={[ 'WRITE_WORK_RECORDS' ]}>
										{r.needsChange && <Button onClick={() => processWorkRecord({ variables: { id: r.id } })}><span className="fa fa-check"/></Button>}
									</PrivilegeBarrier>
								</td>
								<td><FormattedDate value={DateTime.fromISO(r.startDateTarget)} weekday="short" /></td>
								<td><FormattedDate value={DateTime.fromISO(r.startDateTarget)} day="2-digit" month="2-digit" year="numeric" /></td>
								<td><FormattedTime value={DateTime.fromISO(r.startDateTarget)} /></td>
								<td><FormattedTime value={DateTime.fromISO(r.endDateTarget)} /></td>
								<td><ColorDot hex={r.roleTarget?.colorCode || '#fff'} /> {r.roleTarget?.shortCode}</td>
								<td>{r.absenceTypeTarget == null ? null : <AbsenceType type={r.absenceTypeTarget} />}</td>
								<td title={r.remarkTarget}>{r.remarkTarget}</td>
								<td style={{ textAlign: 'right' }}>
									{r.planSpot
										? (r.calculatedWageTarget != null
											? (r.warningOnWageCalculationTarget
												? (<span className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} title={`Aangepaste tijden, loonberekening mogelijk niet correct. Berekening gebaseerd op normale tijden: ${formatCurrency(r.calculatedWageTarget)}`}/>)
												: <FormattedNumber value={r.calculatedWageTarget} style="currency" currency="EUR" />)
											: '-')
										: null
									}
								</td>
								<td style={{ textAlign: 'right' }}>{r.calculatedPremiumTarget != null ? <FormattedNumber value={r.calculatedPremiumTarget} style="currency" currency="EUR" /> : (r.absenceTypeTarget != null ? null : '-')}</td>
							</tr>
						</>;
					})}
				</tbody>
			</table>
		</div>
	</div>;
}